import { Sidebar } from "./Sidebar";

import { Outlet } from "react-router-dom";

export const DashboardLayout = () => {
  return (
    <div className="flex w-screen h-full bg-gray">
      <Sidebar />
      <div className="mt-[72px] md:mt-0 w-screen flex overflow-hidden relative">
        <div className="flex flex-1 overflow-hidden">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
