import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Button";
import { Post } from "components/Post/Post.component";
import { FormTextArea } from "components/TextInput/FormTextArea";
import { FormTextInput } from "components/TextInput/FormTextInput";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import {
  Post as PostType,
  useMeQuery,
  useSuggestPostMutation,
} from "modules/api/generated-api";
import { yup } from "modules/autoimports/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { PROFILE_PATH } from "routes/paths";

interface FormData {
  numberOfSuggestions: number;
  topic?: string;
}

const schema = yup
  .object({
    numberOfSuggestions: yup
      .number()
      .required()
      .max(30)
      .min(1)
      .integer()
      .label("Number of suggestions"),
    topic: yup.string().optional().label("Topic"),
  })
  .required();

export function PlanPostPage() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      numberOfSuggestions: 3,
    },
    resolver: yupResolver(schema),
  });
  const [suggestPost, { isLoading }] = useSuggestPostMutation();
  const navigate = useNavigate();
  const [posts, setPosts] = useState<PostType[]>([]);
  const { data: meData, isFetching: isFetchingMe } = useMeQuery();

  useEffect(() => {
    if (
      !isFetchingMe &&
      (!meData?.me.questionInsights ||
        Object.values(meData?.me.questionInsights).length === 0)
    ) {
      toast("Please fill your profile to plan a post💫", {
        className: "bg-primary text-white",
      });
      navigate(PROFILE_PATH);
    }
  }, [isFetchingMe, meData?.me.questionInsights, navigate]);

  const onSubmit = async (data: FormData) => {
    try {
      const returnedPosts = await apiToastPromise(
        suggestPost({ input: data }),
        {
          error: (e) => {
            return (
              "Error generating posts" +
              (e.errorFiltered ? `: ${e.errorFiltered}` : "")
            );
          },
          loading: "Generating posts...",
          success: "Posts successfully generated",
        },
      );
      if (!returnedPosts?.data?.suggestPost.posts) return;
      setPosts(returnedPosts?.data?.suggestPost.posts as PostType[]);
    } catch (e) {
      console.log(e);
    }
  };

  const hasPosts = posts.length > 0;
  const topic = watch("topic");

  return (
    <div className="w-full flex flex-col items-center sm:justify-center overflow-y-scroll p-4">
      {hasPosts && (
        <div className="flex flex-col sm:justify-center flex-1 w-full">
          <div className="flex items-center gap-2 mb-4">
            <Button
              text="Back"
              onClick={() => {
                setPosts([]);
              }}
            />
            <div className="flex-1 sm:text-center">
              {topic && (
                <span>
                  Ideas generated for <b>{topic}</b>
                </span>
              )}
              {!topic && (
                <span>
                  Ideas generated <b>based on your profile</b>
                </span>
              )}
            </div>
          </div>
          <div className="flex-1" />
          <div className="flex flex-wrap justify-center gap-4">
            {posts.map((post, index) => (
              <Post post={post} key={`suggestions-${index}`} />
            ))}
          </div>
          <div className="flex-1" />
        </div>
      )}
      {!hasPosts && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-2 bg-background p-8 rounded-lg shadow-md w-full max-w-lg relative transform"
        >
          <FormTextArea
            id="topic"
            register={register}
            error={errors.topic}
            rows={4}
            label="Topic"
            placeholder="Type anything you want to post about or keep it empty 🧙"
          />
          <FormTextInput
            id="numberOfSuggestions"
            register={register}
            error={errors.numberOfSuggestions}
            label="Number of suggestions"
            type="number"
          />
          <div className="text-center font-medium">
            {meData?.me.credits} Credits Left
          </div>

          <Button
            disabled={isLoading}
            type="submit"
            text="Generate 🌟"
            className="mt-4"
          />
        </form>
      )}
    </div>
  );
}
