import { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

export const rtkQueryErrorLogger: Middleware =
  // eslint-disable-next-line unused-imports/no-unused-vars
  (store: MiddlewareAPI) => (next) => (action: any) => {
    if (isRejectedWithValue(action)) {
      if (process.env.REACT_APP_ENV == "dev") {
        console.warn("RTK Query Error:", action.payload.stack);
      }
      toast.error("An error occurred");
    }
    return next(action);
  };
