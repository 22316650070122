import { authSlice } from "@redux/slices/auth.slice";

import { api } from "modules/api/generated-api";

export const enhancedDataServiceApi = api.enhanceEndpoints({
  endpoints: {
    Login: {
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(authSlice.actions.setToken(data.login.authToken));
          dispatch(authSlice.actions.setCurrentUser(data.login.user._id));
        } catch (e) {}
      },
    },
    Me: {
      providesTags: ["User"],
    },
    SuggestPost: {
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(api.util.invalidateTags(["User"]));
        } catch (e) {}
      },
    },
    UpdateMe: {
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(api.util.invalidateTags(["User"]));
        } catch (e) {}
      },
    },
  },
});
