import {
  LOGIN_PATH,
  PLAN_POST_PATH,
  PREVIOUS_POSTS_PATH,
  PROFILE_PATH,
  ROOT_PATH,
  SIGNUP_PATH,
} from "./paths";
import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";

import App from "app/App";
import { DashboardLayout } from "app/dashboard/Dashboard.layout";
import { LoggedInLayout } from "app/LoggedIn.layout";
import { Login } from "app/login/Login.page";
import { PlanPostPage } from "app/plan-post/PlanPost.page";
import { PreviousPostsPage } from "app/previous-posts/PreviousPosts.page";
import { ProfilePage } from "app/profile/Profile.page";
import { Signup } from "app/signup/Signup.page";
import { createBrowserRouter, Navigate } from "react-router-dom";

export const router = createBrowserRouter([
  {
    children: [
      {
        children: [
          {
            children: [
              {
                children: [
                  {
                    children: [],
                    element: <PlanPostPage />,
                    path: PLAN_POST_PATH,
                  },
                  {
                    children: [],
                    element: <PreviousPostsPage />,
                    path: PREVIOUS_POSTS_PATH,
                  },
                  {
                    children: [],
                    element: <ProfilePage />,
                    path: PROFILE_PATH,
                  },
                ],
                element: <DashboardLayout />,
              },
            ],
            element: <LoggedInLayout />,
          },
          {
            element: <Navigate to={PLAN_POST_PATH} replace />,
            path: "*",
          },
        ],
        element: <PrivateRoutes />,
      },
      {
        children: [
          {
            element: <Login />,
            path: LOGIN_PATH,
          },
          {
            element: <Signup />,
            path: SIGNUP_PATH,
          },
        ],
        element: <PublicRoutes />,
      },
    ],
    element: <App />,
  },
  {
    element: <Navigate to={ROOT_PATH} replace />,
    path: "*",
  },
]);
