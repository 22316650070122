import { ForwardedRef, forwardRef } from "react";
import { IconType } from "react-icons";
import { useNavigate, useNavigation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

const styles = tv({
  compoundVariants: [
    {
      class: {
        background: "",
        button: "hover:bg-primary",
      },
      color: "primary",
    },
  ],
  slots: {
    button:
      "flex items-center gap-2 px-4 py-2 font-medium text-ink hover:text-white rounded-md drop-shadow-md text-left",
  },
  variants: {
    color: {
      primary: {},
    },
  },
});

type Variant = {
  [K in keyof (typeof styles)["variants"]]: keyof (typeof styles)["variants"][K];
};

type ButtonColor = Variant["color"];

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  Icon: IconType;
  className?: string;
  color?: ButtonColor;
  path?: string;
  text: string;
}

const SidebarButton = forwardRef(
  (
    {
      className,
      color = "primary",
      text,
      Icon,
      path,
      onClick,
      ...props
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const classNames = styles({ color });
    const navigate = useNavigate();
    const navigation = useNavigation();

    return (
      <button
        ref={ref}
        {...props}
        onClick={(e) => {
          if (path) {
            if (location.pathname === path) {
              navigate(0);
            } else {
              navigate(path);
            }
          }
          onClick && onClick(e);
        }}
        className={twMerge(
          classNames.button(),
          className,
          props.disabled && "opacity-50",
          location.pathname === path && "font-extrabold",
        )}
      >
        <Icon className="size-8" />
        <div>{text}</div>
      </button>
    );
  },
);

export default SidebarButton;
