import { twMerge } from "tailwind-merge";

export interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  handleBottomSheetKeyboard?: boolean;
  hasError?: boolean;
  hasIcon?: boolean;
  isFieldValid?: boolean;
  register?: any;
}

export function TextInput(props: TextInputProps) {
  const { register, ...otherProps } = props;
  return (
    <input
      {...otherProps}
      className={twMerge(
        "w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 text-black",
        props.className,
      )}
      {...(register ? register(props.id) : [])}
    />
  );
}
