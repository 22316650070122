import { useAppSelector } from "@redux/hooks";

import { differenceInDays } from "date-fns";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const LoggedInLayout = () => {
  const { lastAskedEnableNotifications } = useAppSelector(
    (state) => state.app.flags,
  );
  // Add later if you need notifications
  const shouldShowEnableNotifications = useMemo(
    () =>
      lastAskedEnableNotifications === undefined ||
      (lastAskedEnableNotifications &&
        differenceInDays(new Date(), lastAskedEnableNotifications)) > 1,
    [lastAskedEnableNotifications],
  );
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/") {
      // redirect
    }
  }, [location.pathname, navigate]);
  return (
    <>
      <Outlet />
      {/* {shouldShowEnableNotifications &&
        window?.Notification?.permission === "default" && (
          <EnableNotificationModal />
        )} */}
    </>
  );
};
