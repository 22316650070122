import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Button";
import { LoadingIndicator } from "components/LoadingIndicator/LoadingIndicator";
import { FormTextInput } from "components/TextInput/FormTextInput";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import {
  useGetQuestionForUserQuery,
  useMeQuery,
  useUpdateMeMutation,
} from "modules/api/generated-api";
import { yup } from "modules/autoimports/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type Conversation = {
  message: string;
  type: "bot" | "user";
};
interface FormData {
  userMessage: string;
}

const schema = yup
  .object({
    userMessage: yup.string().label("Message").required(),
  })
  .required();

export function ProfilePage() {
  const { data: meData, isLoading } = useMeQuery();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const [updateMe, { isLoading: isUpdatingMe }] = useUpdateMeMutation();
  const { data: questionData, refetch: refetchQuestion } =
    useGetQuestionForUserQuery();
  const navigate = useNavigate();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const [conversation, setConversation] = useState<Conversation[]>([]);

  const onSubmit = async (formData: FormData) => {
    setConversation((value) => [
      ...value,
      { message: formData.userMessage, type: "user" },
    ]);
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
    await apiToastPromise(
      updateMe({
        input: {
          questionInsights: [
            ...(meData?.me.questionInsights ? meData?.me.questionInsights : []),
            {
              answer: formData.userMessage,
              question: questionData?.getQuestionForUser._id,
            },
          ],
        },
      }),
      {
        error: "Error updating profile",
        loading: "Updating profile...",
        success: "Profile successfully updated",
      },
    );
    await refetchQuestion();
    reset();
    // navigate(PLAN_POST_PATH);
  };

  useEffect(() => {
    if (!questionData?.getQuestionForUser.question) return;
    setConversation((value) => [
      ...value,
      { message: questionData?.getQuestionForUser.question || "", type: "bot" },
    ]);
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  }, [questionData?.getQuestionForUser.question]);

  const questionInsights = meData?.me.questionInsights || [];
  return (
    <div className="w-full bg-gray-100 flex flex-col p-4">
      <div className="flex flex-col bg-background p-8 rounded-lg shadow-md w-full flex-1 relative  overflow-y-hidden">
        {/* <h1 className="text-2xl font-bold mb-6">Talk about yourself</h1> */}
        {isLoading && <LoadingIndicator className=" self-center py-4" />}
        {!isLoading && (
          <div className="flex flex-1 gap-4 overflow-y-hidden p-1 -m-1">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col space-y-4 flex-[2]"
            >
              <div className="flex flex-col gap-2 border border-gray rounded-xl flex-1 p-4 overflow-y-scroll">
                {conversation.map((conversationItem, index) => (
                  <div
                    className={twMerge(
                      conversationItem.type === "bot" &&
                        "mr-10 animate-[comeDown_0.5s_ease-in-out]",
                      conversationItem.type === "user" &&
                        "self-end text-right ml-10 animate-[comeDown_0.5s_ease-in-out]",
                    )}
                    key={`citem-${index}`}
                  >
                    <div className="font-bold">
                      {conversationItem.type === "bot" ? "Bot" : "You"}
                    </div>
                    <div className="font-light">
                      {index === 0 && (
                        <div className="mb-2 italic">
                          Hi there, I will ask you some questions to get to know
                          you more. This will be used to personalize your posts
                        </div>
                      )}
                      {conversationItem.message}
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
              <div className="relative">
                <FormTextInput
                  id="userMessage"
                  inputClassName="flex border border-gray w-full rounded-xl pr-[100px] resize-none"
                  register={register}
                  error={errors.userMessage}
                  autoFocus
                  autoComplete="off"
                  placeholder="Answer here..."
                />
                <Button
                  disabled={isUpdatingMe}
                  className="absolute right-0 bottom-0"
                  type="submit"
                  text="Submit"
                />
              </div>
            </form>
            <div className="flex flex-col gap-4 flex-1 max-w-[400px] min-w-[200px]">
              <div className="text-center">Memory</div>
              <div className="border border-gray rounded-xl overflow-y-scroll">
                {questionInsights.length === 0 && (
                  <div className="p-1 text-center">No items in memory</div>
                )}
                {questionInsights.map((questionInsight, index) => (
                  <div
                    key={`trait-${index}`}
                    className={twMerge(
                      "flex border-gray p-1",
                      index !== questionInsights.length - 1 && "border-b",
                    )}
                  >
                    <div className="flex-1 p-1">{questionInsight.insight}</div>
                    <div className="flex flex-row-reverse min-w-14 p-1 gap-1">
                      <MdDeleteOutline
                        className=" cursor-pointer"
                        aria-label="Forget"
                        onClick={async () => {
                          await apiToastPromise(
                            updateMe({
                              input: {
                                questionInsights:
                                  meData?.me.questionInsights.filter(
                                    (q) =>
                                      q.question._id !==
                                      questionInsight.question._id,
                                  ),
                              },
                            }),
                            {
                              error: "Error updating profile",
                              loading: "Updating profile...",
                              success: "Profile successfully updated",
                            },
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
