import { NotificationUpdateHandler } from "../notification-update-handler.interface";

import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
  GetAllUserNotificationsQuery,
  NotificationSubscriptionResponse,
} from "modules/api/generated-api";

export class NotificationAllReadHandler implements NotificationUpdateHandler {
  handleNotificationUpdate({
    draft,
    subscriptionResponse,
  }: {
    draft: MaybeDrafted<GetAllUserNotificationsQuery>;
    subscriptionResponse: NotificationSubscriptionResponse;
  }) {
    const { allRead } = subscriptionResponse;
    if (allRead) {
      draft.getAllUserNotifications.forEach((notification, index) => {
        draft.getAllUserNotifications[index] = {
          ...draft.getAllUserNotifications[index],
          isRead: true,
        };
      });
    }
  }
  forSubscription: keyof NotificationSubscriptionResponse = "allRead";
}
