import { TextInput, TextInputProps } from "./TextInput";

import React from "react";
import { FieldError } from "react-hook-form";
import { twMerge } from "tailwind-merge";

interface FormInputProps extends TextInputProps {
  error?: FieldError;
  id: string;
  inputClassName?: string;
  label?: string;
  register: any;
  type?: string;
}

export const FormTextInput: React.FC<FormInputProps> = ({
  id,
  label,
  type,
  register,
  error,
  className,
  inputClassName,
  ...props
}) => {
  return (
    <div className={twMerge(" overflow-visible", className)}>
      {label && (
        <label htmlFor={id} className="block mb-2 text-sm font-medium text-ink">
          {label}
        </label>
      )}
      <TextInput
        {...props}
        className={inputClassName}
        id={id}
        type={type}
        register={register}
      />
      {error?.message && (
        <p className="mt-1 text-sm text-red-600">{error.message}</p>
      )}
    </div>
  );
};
